.mission_pannel_collapse {
  margin-top: 15px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.mission_pannel_collapse.active {
  max-height: none;
}
