.modal-90w {
  width: 90%;
  max-width: 90% !important;
}

.logRow,
.logRowHeader {
  display: flex;
}
.logRow {
  margin-top: 2px;
}
.logRow div {
  background-color: #f4f4f4;
  padding: 5px 15px;
}
.logRowHeader div {
  text-align: center;
}
.logRow div:first-child {
  border-radius: 8px 0 0 8px;
}
.logRow div:last-child {
  border-radius: 0 8px 8px 0;
}
.logRow:hover div {
  background-color: #e2e2e2;
}
.logSearch {
  font-weight: bolder;
  color: black;
}
.logCode {
  padding: 3px;
  font-size: small;
  position: relative;
  top: -2px;
  border-radius: 6px;
  font-family: 'Courier New', Courier, monospace;
}
.logCodeNumber {
  color: #a00;
  padding: 3px;
  font-size: small;
  position: relative;
  top: -2px;
  border-radius: 6px;
  font-family: 'Courier New', Courier, monospace;
  background-color: #ffe0e0;
}
