.listgroup-small {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  cursor: pointer;
}

.listgroup-small img {
  width: 25px;
  height: 25px;
  margin-right: 0.4rem;
}

.artwork {
  width: 250px;
  height: 250px;
}

.icon-hover {
  color: #ccc;
  margin-right: 0.4rem;
  font-size: large;
}
.icon-hover:hover {
  color: black;
  cursor: pointer;
}

.button-grp button {
  margin: 0.1rem;
}

.edit-img-container {
  position: absolute;
  z-index: 100;
  right: -10px;
  bottom: 0px;
}

.edit-img {
  display: flex;
  width:34px;
  height: 34px;
  border-radius: 100%;
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
}

.edit-img i {
  color: #757575;
}


.back-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.back-button {
  font-size: 2rem;
}