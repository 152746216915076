.cp-list .padded-plaintext-control {
  color: #6e707e !important;
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent !important;
}

.cp-list .square-icon {
  padding: 0 !important;
  text-align: center;
  line-height: 1rem;
  width: 2.375rem;
  height: 2.375rem;
}
.cp-list td .square-icon {
  margin: 1px;
}
.cp-list .square-icon i {
  width: 1rem;
}

.cp-list .square-icon.icon-sm {
  width: 2rem;
  height: 2rem;
}
.cp-list .square-icon.icon-tiny {
  width: 1.5rem;
  height: 1.5rem;
}

.cp-list .icon-remove {
  transition: none;
}
.cp-list .icon-remove:hover {
  background-color: #e74a3b;
  border-color: #e74a3b;
}
.cp-list .icon-remove:hover:active {
  background-color: #d52a1a;
  border-color: #ca2819;
}
.cp-list .icon-remove:hover:focus {
  box-shadow: 0 0 0 0.2rem rgb(235 101 88 / 50%);
}
.cp-list .icon-remove:hover i::before {
  content: '\f00d';
}

.cp-list .nav-pills {
  border-radius: 0.35rem;
  background-color: #eee;
}

.cp-list .nav-pills .nav-link:not(.active) {
  color: #555;
}
