.btn-toolbar-item {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.edit-span:hover {
  opacity: 0.8;
  cursor: pointer;
}
.edit-btn {
  font-size: 1rem;
}
