.artwork-popup {
  width: 100px;
  height: 100px;
  margin-right: 0.5rem;
}

.modal-title-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
