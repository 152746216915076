.itunes thead th {
  text-align: center !important;
  vertical-align: middle;
}

th.sortable {
  cursor: pointer;
  position: relative;
}

.itunes th.sortable {
  padding-right: 2rem;
}

th.sortable::after,
th.sortable::before {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  right: 12px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

th.sortable::before {
  border-top: 4px solid #000;
  bottom: 10px;
}

th.sortable::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 8px;
}

th.sortable.sorted_asc::before,
th.sortable.sorted_desc::after {
  opacity: 0.6;
}

.itunes-label span {
  word-break: break-all;
}
