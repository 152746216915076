.UserSubscriptionLogDisplay_log {
  background-color: #eee;
  border-radius: 5px;
  display: flex;
  margin-top: 5px;
}

.UserSubscriptionLogDisplay_log div {
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.UserSubscriptionLogDisplay_log div div {
  text-align: center;
}
