.chip {
  background-color: #bae3e1;
  border-radius: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 0 0.3rem 0.3rem 0;
  color: #4e9bdf;
}
.chip:hover{
    background-color: #4e9bdf;
    color: #bae3e1;
}
.chip > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip > .button:hover{
    background-color: gray;
}

.chip-outlined {
    border-width: "1px";
    border-style: solid;
    border-color: #bae3e1;
    background-color: rgba(0,0,0,0);
}

.chip-outlined:hover {
    color: #4e9bdf;
    background-color: rgba(0,0,0,0);
    border-color: #4e9bdf;
}