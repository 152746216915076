.img-contain {
  object-fit: contain;
}

.img-cover {
  object-fit: cover;
}

*:focus {
  outline: none;
}

/* 
Custom horizontal collapse class definition,
.width not supported yet by bootstrap.
*/

.collapse {
  visibility: hidden;
}
.collapse.show {
  visibility: visible;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}

/* Custom spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.liveops-nav-link-old {
  color: gray;
}

.liveops-nav-link-old.active {
  color: white;
}

.liveops-nav-link-current {
  color: green;
}
