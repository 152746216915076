.graphql-voyager > .menu-content {
  left: 580px;
}

.loading-box {
  left: 224px;
}

.type-info-popover {
  left: 0;
}
