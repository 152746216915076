.play-button {
  color: #ccc;
  cursor: pointer;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}

.play-button:hover {
  color: black;
}
